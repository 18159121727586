<!--
 * @Author: your name
 * @Date: 2021-04-16 15:17:35
 * @LastEditTime: 2021-05-17 18:17:16
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one\src\views\resume\index.vue
-->
<template>
  <div class="allmsgclass2">
    <navigation
      :noleft="true"
      :customizeLeft="true"
      :stylemsg="stylemsg"
      msg="简历公海"
    >
      <!-- <span
        :class="menuindex != 0 ? 'dingyue2' : 'dingyue'"
        @click="$router.push('/dingyue')"
        >订阅设置</span
      > -->
      <template>
        <div @click="$router.push('resumedownload')" class="downclass">
          <div>已下载</div>
          <van-icon size=".35rem" class="resume_down_class" name="back-top" />
        </div>
      </template>
    </navigation>
    <van-sticky>
      <div>
        <div class="daohang">
          <div class="sh1">
            <div @click="clickmenu(1)" :class="menuindex == 1 ? 'sem' : ''">
              转介绍
            </div>
            <div @click="clickmenu(0)" :class="menuindex == 0 ? 'sem' : ''">
              当前岗位
            </div>
            <div @click="clickmenu(3)" :class="menuindex == 3 ? 'sem' : ''">
              人才库
            </div>
          </div>
        </div>
        <div class="shaiclass">
          <div class="tuijianclass" v-show="menuindex == 3">
            <div v-show="menuindex == 3" @click="filtermeth" class="sh3">
              筛选条件<img src="../../assets/shai.png" alt="" />
              <div v-show="selenum != 0 && menuindex != 1" class="tiaojian">
                {{ selenum }}
              </div>
            </div>
          </div>
          <div class="tuijianclass" v-show="menuindex == 0">
            <div
              v-show="menuindex == 0"
              @click="showdingyue = true"
              class="sh3"
            >
              筛选条件<img src="../../assets/shai.png" alt="" />
            </div>
          </div>
          <div class="tuijianclass" v-show="menuindex == 1">
            <div
              :style="seindex2 == 0 ? 'color:#FE5903' : ''"
              @click="selectmeth2('可推荐', 0)"
            >
              可推荐
            </div>
            <div
              :style="seindex2 == 1 ? 'color:#FE5903' : ''"
              @click="selectmeth2('已推荐', 1)"
            >
              已推荐
            </div>
          </div>
        </div>
      </div>
    </van-sticky>
    <div class="allmsgclass">
      <div v-show="menuindex == 3 && selenum < 1">
        <div class="tuiclass">推荐岗位</div>
        <div class="tuijianallclass">
          <div
            @click="recommend(item)"
            class="tuijiandiv"
            v-for="(item, index) in tuijianArray"
            v-bind:key="index"
          >
            {{ item.split("/")[item.split("/").length - 1] }}
            <img
              v-if="index == 0 || index == 1"
              class="hostimgclass"
              src="../../assets/host.png"
              alt=""
            />
          </div>
          <div @click.stop="" class="tuijiandiv2"></div>
          <div @click.stop="" class="tuijiandiv2"></div>
        </div>
      </div>
      <div class="xilac" v-for="(item, index) in listmsg" v-bind:key="index">
        <div @click="tosub(item)" class="msgccl" v-show="menuindex != 1">
          <div class="msgclass">
            <div class="xinxi">
              <div>
                <div class="userclass">
                  <img
                    v-if="(item.sex + '').indexOf('男') != -1"
                    class=""
                    src="../../assets/user_1.png"
                    alt=""
                  />
                  <img v-else class="" src="../../assets/user_2.png" alt="" />
                  <img
                    class="shimingclass"
                    src="../../assets/shiming.png"
                    alt=""
                  />
                </div>
              </div>
              <div class="mac">
                <div class="listc">
                  <div class="nayu">
                    <div>{{ item.name }}</div>
                    <img
                      v-if="(item.sex + '').indexOf('男') != -1"
                      src="../../assets/sex_1.png"
                      alt=""
                    />
                    <img
                      v-if="(item.sex + '').indexOf('女') != -1"
                      src="../../assets/sex_2.png"
                      alt=""
                    />
                    <div>{{ item.politics }}</div>
                  </div>
                  <div>
                    <div class="kaic">{{ item.jop }}</div>
                  </div>
                </div>
                <div class="xiao">
                  <div>
                    {{ item.years_working }}年 |
                    {{ item.education_background }} |
                    {{ item.salary }}
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
            <!-- <div>
            <div></div>
            <div class="kaic">{{item.jop}}</div>
          </div> -->
          </div>
          <div class="hycla">
            <div></div>
            <div>期望行业：{{ item.industry }}</div>
          </div>
          <div class="hycla hycla2">
            <div></div>
            <div>推荐公司：{{ item.company }}</div>
          </div>
          <div class="hycla hy2c">
            <div></div>
            <div>{{ item.add_time }}</div>
          </div>
          <img class="himgclass" src="../../assets/ri1.png" alt="" />
        </div>

        <div
          @click="totrsumeshare(item)"
          v-show="menuindex == 1"
          claSS="fxclass"
        >
          <div class="fxclass2">
            <div class="fxclass3">
              <img
                v-if="(item.sex + '').indexOf('男') != -1"
                src="../../assets/user_1.png"
                alt=""
              />
              <img
                v-else-if="(item.sex + '').indexOf('女') != -1"
                src="../../assets/user_2.png"
                alt=""
              />
              <img v-else src="../../assets/user_1.png" alt="" />
            </div>
          </div>
          <div class="msgf">
            <div class="fxclass7">
              <div class="fxclass4">
                <span>{{ item.name }}</span
                ><span>候选人</span>
              </div>
              <div
                :style="
                  item.is_exists == 1
                    ? 'color:#ff6d20'
                    : item.status_type == '60'
                    ? 'color:#ff6d20'
                    : item.status_type == '30'
                    ? 'color:#0f98e7'
                    : item.status_type == '10' ||
                      item.status_type == '20' ||
                      item.status_type == '50'
                    ? 'color:#67c23a'
                    : ''
                "
                class="yfx1"
              >
                <div v-if="item.is_exists != 1">
                  {{ getType(item.status_type) }}
                </div>
                <div v-else>
                  {{ getType1(item.status_type) }}
                </div>
                <van-icon
                  :class="
                    item.is_exists == 1
                      ? 'classhover1'
                      : item.status_type == '60'
                      ? 'classhover2'
                      : item.status_type == '30'
                      ? 'classhover3'
                      : item.status_type == '10' ||
                        item.status_type == '20' ||
                        item.status_type == '50'
                      ? 'classhover4'
                      : item.status_type == '70'
                      ? 'classhover5'
                      : 'classhover6'
                  "
                  @click.stop=""
                  size=".3rem"
                  name="question-o"
                />
              </div>
            </div>
            <div class="pingjiacla">
              <div class="fxclass5">
                <div>更新时间：{{ (item.add_time + "").split(" ")[0] }}</div>
              </div>
              <div class="yfx2">
                成功推荐次数：<span>{{ item.downloadCount }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <loading :isCustomize="true" @loaddom="loaddom" ref="loades">
      <template>
        <div v-show="menuindex == 0">
          <div class="thetemclass">
            <img class="nomsgclassimg" src="../../assets/gw.png" alt="" />
          </div>
          <div class="zpclass">订阅招聘岗位，每天轻松坐收批量简历!</div>
          <div class="boclass">
            <div>
              1.点击底部【开始订阅岗位】，设置岗位条件，对岗位进行状态管理；
            </div>
            <div>
              2.点击右上角【筛选】按钮，选择已订阅岗位和系统精准匹配的简历。
            </div>
          </div>
        </div>
        <div v-show="menuindex == 1">
          <div class="thetemclass">
            <img class="nomsgclassimg" src="../../assets/usnomsg.png" alt="" />
          </div>
          <div class="zpclass">您的转介绍，可能帮TA改变职场生涯哦~</div>
          <div class="boclass3 boclass2">
            <div>
              <div>step1：点击底部【新增转介绍】按钮；</div>
              <div>step2：20秒轻松完成一份简历上传；</div>
              <div>step3：上传成功，余额奖励(20-50<br />不等)即刻到账！</div>
            </div>
          </div>
          <div class="timemsgall">
            <div class="timemsg">
              <div>{{ floormsg().index }}分钟前</div>
              <div>{{ floormsg().name }}**</div>
              <div>感谢HR举手之劳，已成功入职</div>
            </div>
            <div class="timemsg">
              <div>{{ floormsg().index }}分钟前</div>
              <div>{{ floormsg().name }}**</div>
              <div>感谢HR举手之劳，已成功入职</div>
            </div>
            <div class="timemsg">
              <div>{{ floormsg().index }}分钟前</div>
              <div>{{ floormsg().name }}**</div>
              <div>感谢HR举手之劳，已成功入职</div>
            </div>
          </div>
        </div>
        <div v-show="menuindex == 3">
          <div v-if="selenum != 0">
            <div class="thetemclass">
              <img
                class="nomsgclassimg nomsgclassimg2"
                src="../../assets/nom.png"
                alt=""
              />
            </div>
            <div class="zpclass">离心仪的人才就差一步啦~</div>
            <div class="boclass4">
              <div>
                <div>您的岗位要求有点严格呢~</div>
                <div>建议调整岗位设置，获得更多简历！</div>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="thetemclass">
              <img
                class="nomsgclassimg nomsgclassimg2"
                src="../../assets/rc.png"
                alt=""
              />
            </div>
            <!-- <div class="zpclass">离心仪的人才就差一步啦~</div>
            <div class="boclass3 boclass2">
              <div>
                <div>1.点击【人才库】右边的【筛选】；</div>
                <div>2.选择招聘岗位，设置筛选条件；</div>
                <div>3.获得人才简历。</div>
              </div>
            </div> -->
          </div>
        </div>
      </template>
    </loading>

    <div v-show="menuindex == 1" class="fenxiang">
      <button @click="addshare">
        <img src="../../assets/us.png" alt="" /> 新增转介绍
      </button>
    </div>
    <div
      @click="$router.push('/dingyue')"
      v-show="menuindex == 0"
      class="fenxiang"
    >
      <button>
        <img src="../../assets/dy.png" alt="" /> 开始/修改订阅岗位
      </button>
    </div>
    <div :class="menuindex != 3 ? 'nomsgclass2' : 'nomsgclass'"></div>
    <filtermenu
      :searray="searray"
      @shaimeth="shaimeth"
      ref="filterid"
    ></filtermenu>
    <van-action-sheet
      v-model="showshare"
      :actions="actionshare"
      cancel-text="取消"
      description="请选择推荐筛选"
      close-on-click-action
      @select="selectmeth2"
    />
    <!-- 订阅列表选择 -->
    <van-action-sheet
      v-model="showdingyue"
      :actions="dingyuelist"
      cancel-text="取消"
      close-on-click-action
      @select="clickdingyue"
    />
    <sharepage></sharepage>
  </div>
</template>
<script>
import loading from "../../components/loading2/index";
import filtermenu from "../../components/filtermenu/index";
import eventBus from "../../../util/eventbus";
import sharepage from "../../views/sharepage/index";
import family from "../../../util/family";
const MY_POST = 0; //我的岗位
const MY_RECOMMEND = 1; //我的推荐
const TALENT_POLL = 3; //人才库
const FILTER_REALNAME_NO = 1; //未实名认证（筛选）
export default {
  components: {
    loading,
    filtermenu,
    sharepage,
  },

  data() {
    return {
      showdingyue: false, //订阅列表选择是否显示
      stylemsg: "background-color: #f6f6f7;",
      menuindex: "3", //当前导航下标（默认）
      listmsg: [],
      pageno: 0, //页数
      filterarray: ["", "", ""], //过滤条件
      selenum: 0,
      seindex2: 0,
      showshare: false,
      is_to_share: "可推荐", //推荐状态，10--已推荐，20--可推荐 30--上传失败拒绝推荐  40--已下架  根据这个传值 获取不同的状态
      actionshare: [
        { name: "全部" },
        { name: "已推荐" },
        { name: "可推荐" },
        // { name: "拒绝推荐" },
        // { name: "简历下架" },
      ],
      shaimsg: "",
      searray: [],

      seleindex: [], //点击的文件
      dingyuelist: [
        {
          name: "全部",
          id: "",
        },
      ], //订阅列表的值
      tuijianArray: [
        "设计/视觉|交互设计/UI设计师",
        "设计/视觉|交互设计/平面设计",
        "销售/销售管理/销售经理",
        "技术/后端开发/Java",
      ],
    };
  },

  created() {
    // 推荐数据（转介绍未有数据时）展示
    this.floormsg();
    //分享
    this.tosharemeth();
    sessionStorage.removeItem("addshare_name");
    sessionStorage.removeItem("addshare_id_no");
    sessionStorage.removeItem("addshare_mobile");
    sessionStorage.removeItem("addshare_resume");
    if (sessionStorage.getItem("resumeindex")) {
      this.menuindex = sessionStorage.getItem("resumeindex");
    } else {
      this.menuindex = "1";
    }

    if (this.$route.query.resumeindex) {
      this.menuindex = this.$route.query.resumeindex;
    }
    this.getdingyuelist();
  },
  destroyed() {},
  methods: {
    floormsg() {
      return family();
    },
    /**
     *点击订阅列表
     */
    clickdingyue(e) {
      if (e.name == "全部") {
        this.clickmenu(this.menuindex);
        return;
      }
      this.pageno = 0;
      this.getmsg("/firm/v1/Resume/pushResumeList", {
        reqType: "resume",
        pageno: ++this.pageno,
        limit: 15,
        sub_id: e.id,
        education_background: "",
        salary: "",
        age: "",
        jop: "",
        years_working: "",
        sex: "",
        politics: "",
        industry: "",
      });
    },
    /**
     * 获取订阅列表
     */
    getdingyuelist() {
      this.$http
        .post("/firm/v1/Resume/subscribeLists", {
          pageno: 1,
          reqType: "resume",
          limit: 15,
          type: 1, //1-开启 2-关闭 不传查询全部
        })
        .then((res) => {
          let listmsg = JSON.parse(res.data).data.subscribreLists;
          listmsg.forEach((e) => {
            this.dingyuelist.push({ name: e.theme, id: e.id });
          });
        });
    },

    //禁止展示推荐失败
    noshowmeth(item) {
      if (item.is_exists == 1) {
        if (
          item.status_type == 10 ||
          item.status_type == 20 ||
          item.status_type == 50
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    // class 属性判断
    getclassmeth(msg) {
      if (msg.is_exists == 1) {
        if (msg.status == 10 || msg.status == 20 || msg.status == 50) {
          return "classhover1";
        } else if (msg.status == 30) {
          return "classhover3";
        } else if (msg.status == 60) {
          return "classhover7";
        } else if (msg.status == 70) {
          return "classhover5";
        }
      } else {
        if (msg.status == 10 || msg.status == 20 || msg.status == 50) {
          return "classhover4";
        } else if (msg.status == 30) {
          return "classhover3";
        } else if (msg.status == 60) {
          return "classhover7";
        } else if (msg.status == 70) {
          return "classhover5";
        }
      }
    },
    getType1(status) {
      let str = "";
      switch (status) {
        case 10:
          str = "当前不可推荐";
          break;
        case 20:
          str = "当前不可推荐";
          break;
        case 30:
          str = "已推荐";
          break;
        case 50:
          str = "当前不可推荐";
          break;
        case 60:
          str = "推荐失败";
          break;
        case 70:
          str = "简历下架";
          break;
      }
      return str;
    },
    getType(status) {
      let str = "";
      switch (status) {
        case 10:
          str = "可推荐";
          break;
        case 20:
          str = "可推荐";
          break;
        case 30:
          str = "已推荐";
          break;
        case 50:
          str = "可推荐";
          break;
        case 60:
          str = "推荐失败";
          break;
        case 70:
          str = "简历下架";
          break;
      }
      return str;
    },
    //分享内容
    tosharemeth() {
      let obj = JSON.stringify({
        sharetitle: "免费招聘，一年巨省30万！",
        sharedesc: "10万HR都在这里交换真实新鲜简历",
        isshare: "1",
        share_phone: localStorage.getItem("phone"),
      });
      localStorage.setItem("shareobj", obj);
    },
    //新增能转介绍
    addshare() {
      this.$router.push({
        path: "/shareresume",
        query: {
          isaddshare: "true",
        },
      });
    },
    //选择分享筛选
    selectmeth2(msg, index) {
      this.seindex2 = index;
      this.is_to_share = msg;
      this.clickmenu(this.menuindex);
    },
    //点击下标
    clickindex(index) {
      if (this.seleindex[index] == index) {
        this.$set(this.seleindex, index, "-1");
      } else {
        this.seleindex = [];
        this.$set(this.seleindex, index, index);
      }
    },
    //岗位筛选
    togangwei() {
      this.$router.push("/dingyuelist");
    },
    totrsumeshare(item) {
      if (item.status_type == 30 && item.is_exists != 1) {
        this.$toast("该简历已完善");
        return;
      }
      this.$router.push({
        path: "/shareresume",
        query: {
          msg: JSON.stringify({
            email: item.email,
            school_major: item.school_major,
            household_register: item.household_register,
            id: item.id,
            mobile: item.mobile,
            salary: item.salary,
            id_no: item.id_no,
            jop: item.jop,
            education_background: item.education_background,
            sex: item.sex,
            years_working: item.years_working,
            industry: item.industry,
            politics: item.politics,
            checked: item.checked,
            name: item.name,
            note: item.note,
            share_type: item.share_type,
            resume: item.resume,
          }),
        },
      });
    },
    shaimeth2(msg) {
      let a = 0;
      msg.forEach((e) => {
        if (e != "") {
          a++;
        }
      });
      this.shaimsg = msg;
      this.selenum = a;
      this.filterarray = msg;
    },
    /**
     * 点击推荐岗位
     */
    recommend(msg) {
      this.filterarray = new Array(9).fill("");
      this.filterarray[7] = msg;
      //是否实名 默认搜索实名数据
      this.filterarray[9] = "2";
      //默认搜索地址
      this.filterarray[8] = "上海市/上海市";
      let a = 0;
      this.filterarray.forEach((e) => {
        if (e != "") {
          a++;
        }
      });
      this.shaimsg = this.filterarray;
      this.selenum = a;
      this.searray = this.filterarray;
      sessionStorage.removeItem("sub_keepAlive_shaimsg");
      this.clickmenu(this.menuindex);
    },
    shaimeth(msg) {
      let a = 0;
      msg.forEach((e) => {
        if (e != "") {
          a++;
        }
      });
      this.shaimsg = msg;
      this.selenum = a;
      this.filterarray = msg;
      this.clickmenu(this.menuindex);
    },
    filtermeth() {
      if (this.menuindex == 1) {
        this.showshare = true;
      } else {
        this.$refs.filterid.showfilter();
      }
    },
    tosub(item) {
      console.log("====>", item);
      this.$router.push({
        path: "/sub",
        query: {
          id: btoa(item.id),
          is_download: item.is_download,
          sub_id: item.sub_id,
        },
      });
    },
    clickmenu(index) {
      if (arguments[1] != "false") {
        sessionStorage.setItem("resumeindex", index);
        this.menuindex = index;
        this.pageno = 0;
        this.$refs.loades.empty();
        this.listmsg = [];
        this.seleindex = []; //清空选中
      }
      // 我的简历列表
      if (index == MY_POST) {
        this.getmsg(
          "/firm/v1/Resume/pushList",
          this.searchParameters(new Array(9).fill(""), MY_POST)
        );
      } else if (index == MY_RECOMMEND) {
        // 分享简历
        this.getmsg("/firm/v1/resume/share", {
          page: ++this.pageno,
          reqType: "resume",
          education_background: "",
          salary: "",
          status: "",
          jop: "",
          is_to_share:
            this.is_to_share == "全部"
              ? ""
              : this.is_to_share == "已推荐"
              ? "10"
              : this.is_to_share == "可推荐"
              ? "20"
              : this.is_to_share == "拒绝推荐"
              ? "30"
              : this.is_to_share == "简历下架"
              ? "40"
              : "", //推荐状态，10--已推荐，20--可推荐 30--上传失败拒绝推荐  40--已下架  根据这个传值 获取不同的状态
          years_working: "",
          article: 15,
        });
      } else if (index == TALENT_POLL) {
        this.getTalentPoolCacheSearch();
        if (this.filterarray[9] == FILTER_REALNAME_NO) {
          // 未实名最新简历（未实名默认无）
          this.getmsg("/firm/v1/Common/download_resume_list", {
            reqType: "resume",
            page: ++this.pageno,
            education_background: "",
            salary: "",
            age: "",
            jop: "",
            years_working: "",
            sex: "",
            industry: "",
            politics: "",
            area: "",
            article: 15,
          });
        } else {
          // 最新简历
          this.getmsg("/firm/v1/Common/download_resume_list", {
            reqType: "resume",
            page: ++this.pageno,
            education_background: this.filterarray[1],
            salary: this.filterarray[2],
            age: this.filterarray[3],
            jop: this.filterarray[7],
            years_working: this.filterarray[0],
            sex: this.filterarray[5],
            industry: this.filterarray[6],
            politics: this.filterarray[4],
            area: this.filterarray[8] ? this.filterarray[8].split("/") : "",
            article: 15,
          });
        }
      }
    },
    getmsg(url, msg) {
      this.$refs.loades.loadhttpes(url, msg).then((res) => {
        this.listmsg = res.arraymsg;
        if (this.menuindex == 3) {
          sessionStorage.setItem("sub_keepAlive_shaimsg", this.shaimsg);
        }
      });
    },
    loaddom() {
      this.clickmenu(this.menuindex, "false");
    },

    getTalentPoolCacheSearch() {
      if (sessionStorage.getItem("sub_keepAlive_shaimsg")) {
        this.shaimeth2(
          sessionStorage.getItem("sub_keepAlive_shaimsg").split(",")
        );
        this.searray = sessionStorage
          .getItem("sub_keepAlive_shaimsg")
          .split(",");
      }
      return;
    },

    searchParameters(arraymsg, searchindex) {
      if (searchindex == MY_POST) {
        return {
          reqType: "resume",
          pageno: ++this.pageno,
          education_background: arraymsg[1],
          salary: arraymsg[2],
          age: arraymsg[3],
          jop: arraymsg[7],
          years_working: arraymsg[0],
          sex: arraymsg[5],
          industry: arraymsg[6],
          politics: arraymsg[4],
          area: arraymsg[8] ? arraymsg[8].split("/") : "",
          limit: 15,
        };
      }
      return {
        reqType: "resume",
        page: ++this.pageno,
        education_background: arraymsg[1],
        salary: arraymsg[2],
        age: arraymsg[3],
        jop: arraymsg[7],
        years_working: arraymsg[0],
        sex: arraymsg[5],
        industry: arraymsg[6],
        politics: arraymsg[4],
        area: arraymsg[8] ? arraymsg[8].split("/") : "",
        article: 15,
      };
    },
  },
};
</script>
<style scoped>
.downclass {
  display: flex;
  align-items: center;
  color: #f9814f;
  font-weight: 400;
}
.downclass .resume_down_class {
  width: 0.45rem;
  font-weight: 800;
  transform: rotate(180deg);
}
.tuijianallclass {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}
.tuijiandiv {
  font-size: 0.28rem;
  font-weight: 400;
  color: #606266;
  line-height: 0.48rem;
  background-color: #ffffff;
  text-align: center;
  flex: 1;
  white-space: nowrap;
  min-width: 2.08rem;
  margin-right: 0.24rem;
  padding: 0.2rem 0;
  margin-bottom: 0.24rem;
  position: relative;
  border-radius: 0.08rem;
}
.tuijiandiv2 {
  font-size: 0.28rem;
  font-weight: 400;
  color: #606266;
  line-height: 0.48rem;
  background-color: #f6f6f7;
  text-align: center;
  flex: 1;
  white-space: nowrap;
  min-width: 2.08rem;
  margin-right: 0.24rem;
  padding: 0.2rem 0;
  margin-bottom: 0.24rem;
  position: relative;
  border-radius: 0.08rem;
}
.hostimgclass {
  position: absolute;
  right: -0.1rem;
  width: 0.66rem;
  top: 0;
  transform: translateY(-50%);
}
.tuijianallclass > :nth-child(3n) {
  margin-right: 0;
}
.tuiclass {
  font-size: 0.32rem;
  font-weight: 500;
  color: #303133;
  line-height: 0.48rem;
  margin: 0.42rem 0;
}
.timemsgall {
  margin: 0.34rem 0.24rem;
  margin-bottom: 0;
}
.timemsg {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.28rem;
  font-weight: 400;
  color: rgba(69, 113, 208, 0.9);
  line-height: 0.48rem;
  text-align: center;
  padding: 0.04rem 0.38rem;
  background: rgba(69, 113, 208, 0.1);
  border-radius: 0.32rem;
  margin-bottom: 0.16rem;
}
.timemsg > :nth-child(2) {
  margin-left: 0.34rem;
  margin-right: 0.3rem;
}
.tuijianclass {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.28rem;
  font-weight: 400;
  color: #606266;
  line-height: 0.56rem;
  padding: 0.2rem 0;
  background-color: #ffffff;
}
.tuijianclass > :nth-child(2n) {
  margin: 0 0.52rem;
}
.classhover1 {
  position: relative;
}
.classhover1:hover::after {
  content: "该简历已经存在至简历公海";
  top: -0.8rem;
  left: 50%;
  transform: translateX(-80%);
  position: absolute;
  color: white;
  width: 2rem;
  font-size: 0.2rem;
  padding: 0.1rem;
  padding-bottom: 0.15rem;
  background-color: #080808dc;
  border-radius: 0.1rem;
}
.classhover2 {
  position: relative;
}
.classhover2:hover::after {
  content: "推荐失败";
  top: -0.8rem;
  left: 50%;
  transform: translateX(-80%);
  position: absolute;
  color: white;
  text-align: center;
  width: 2rem;
  font-size: 0.2rem;
  padding: 0.1rem;
  padding-bottom: 0.15rem;
  background-color: #080808dc;
  border-radius: 0.1rem;
}
.classhover3 {
  position: relative;
}
.classhover3:hover::after {
  content: "该经历您已经推荐过了";
  top: -0.8rem;
  left: 50%;
  transform: translateX(-80%);
  position: absolute;
  color: white;
  width: 2rem;
  font-size: 0.2rem;
  padding: 0.1rem;
  padding-bottom: 0.15rem;
  background-color: #080808dc;
  border-radius: 0.1rem;
}
.classhover4 {
  position: relative;
}
.classhover4:hover::after {
  content: "请完善简历后再推荐";
  top: -0.8rem;
  left: 50%;
  transform: translateX(-80%);
  position: absolute;
  color: white;
  width: 2rem;
  font-size: 0.2rem;
  padding: 0.1rem;
  padding-bottom: 0.15rem;
  background-color: #080808dc;
  border-radius: 0.1rem;
}
.classhover5 {
  position: relative;
}
.classhover5:hover::after {
  content: "该简历未通过后台审核";
  top: -0.8rem;
  left: 50%;
  transform: translateX(-80%);
  position: absolute;
  color: white;
  width: 2rem;
  font-size: 0.2rem;
  padding: 0.1rem;
  padding-bottom: 0.15rem;
  background-color: #080808dc;
  border-radius: 0.1rem;
}
.classhover6 {
  position: relative;
}
.classhover6:hover::after {
  content: "简历状态获取失败";
  top: -0.8rem;
  left: 50%;
  transform: translateX(-80%);
  position: absolute;
  color: white;
  width: 2rem;
  font-size: 0.2rem;
  padding: 0.1rem;
  padding-bottom: 0.15rem;
  background-color: #080808dc;
  border-radius: 0.1rem;
}
.classhover7 {
  position: relative;
}
.classhover7:hover::after {
  content: "推荐失败";
  top: -0.8rem;
  left: 50%;
  transform: translateX(-80%);
  position: absolute;
  color: white;
  width: 2rem;
  font-size: 0.2rem;
  padding: 0.1rem;
  padding-bottom: 0.15rem;
  background-color: #080808dc;
  border-radius: 0.1rem;
}
.allmsgclass2 button {
  margin: 0;
}
.boclass4 {
  text-align: center;
}
.nomsgclassimg {
  width: 4.92rem;
}
.nomsgclassimg2 {
  width: 3.62rem;
}
.thetemclass {
  text-align: center;
  margin-top: 1rem;
}
.zpclass {
  text-align: center;
  font-size: 0.28rem;
  line-height: 0.4rem;
  color: #303133;
  margin-top: 1rem;
  margin-bottom: 0.68rem;
  font-weight: 500;
}
.boclass {
  font-size: 0.28rem;
  line-height: 0.4rem;
  color: #909399;
  font-weight: 500;
  margin: 0 0.66rem;
}
.boclass3 {
  font-size: 0.28rem;
  line-height: 0.4rem;
  color: #909399;
  font-weight: 500;
}
.boclass2 {
  text-align: left;
  white-space: nowrap;
}
.boclass2 > div {
  margin-left: 43%;
  transform: translateX(-43%);
}

.moremenu {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  margin-bottom: 0.2rem;
  justify-content: space-between;
  padding: 0.2rem;
  background-clip: red;
}
.moremenu > div {
  padding: 0.09rem 0.55rem;
  border-radius: 0.1rem;
  border: 0.01rem solid #fe5600;
  color: #696969;
}
.moremenu > :first-child {
  background: linear-gradient(360deg, #fc9e46 0%, #fe5600 100%);
  color: white;
  border: none;
  padding: 0.1rem 0.55rem;
}
.listc {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nomsgclass2 {
  height: 1.5rem;
}
.yfx1 {
  font-size: 0.24rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #a8a8a8;
  line-height: 0.33rem;
  display: flex;
  align-items: center;
}
.yfx1 > :nth-child(2) {
  padding-left: 0.1rem;
}
.yfx2 {
  font-size: 0.24rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #a8a8a8;
  line-height: 0.33rem;
}
.yfx2 span {
  color: #222222;
}
.pingjiacla {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.msgf {
  width: 100%;
}
.fxclass7 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 0.22rem;
}

.fxclass5 {
  display: flex;
  align-items: center;
}
.fxclass5_1 {
  font-size: 0.18rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.25rem;
  padding: 0.05rem 0.1rem;
  background: #5fcd86;
  border-radius: 0.08rem;
  margin-right: 0.11rem;
}
.fxclass5_2 {
  font-size: 0.18rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.25rem;
  padding: 0.05rem 0.1rem;
  background: #96a5aa;
  border-radius: 0.08rem;
}
.fxclass4 > :first-child {
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222222;
  line-height: 0.42rem;
  margin-right: 0.09rem;
}
.fxclass4 > :nth-child(2) {
  font-size: 0.24rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #cccccc;
  line-height: 0.33rem;
}
.fxclass3 {
  width: 1.07rem;
  height: 1.07rem;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 0.3rem;
}
.fxclass3 img {
  width: 100%;
  height: 100%;
}
.fxclass2 {
  display: flex;
  align-items: center;
}
.fxclass {
  display: flex;
  align-items: center;
  padding: 0.46rem 0.28rem;
  background-color: #ffffff;
  border-radius: 0.1rem;
  margin-bottom: 0.14rem;
}
.nomsgclass {
  height: 0.3rem;
}
.fenxiang {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.48rem;

  background-color: #ffffff;
  border-top-color: #dfdfdf;
  border-top-style: solid;
  border-top-width: 1px;
}
.fenxiang button {
  background: linear-gradient(90deg, #ff4800 0%, #fc9e46 100%);

  border: none;
  padding: 0.24rem 0;
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fenxiang img {
  width: 0.3rem;
  margin-right: 0.12rem;
}
.dingyue {
  font-size: 0.28rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 0.48rem;
}
.dingyue2 {
  font-size: 0.28rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 0.48rem;
  visibility: hidden;
}

.msgclassse {
  margin: 0.35rem 0.32rem;
}

.allmsgclass >>> .van-popup__close-icon--top-left {
  top: 0.3rem;
}
.allmsgclass >>> .van-popup__close-icon {
  font-size: 0.46rem;
}

.hycla {
  display: flex;
  align-items: center;
  margin-top: 0.17rem;
  font-size: 0.24rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #a8a8a8;
  line-height: 0.33rem;
}
.hycla > :first-child {
  min-width: 1.37rem;
}
/* .msgccl {
  background-color: #ffffff;
  padding: 0.46rem 0.23rem;
  border-radius: 0.1rem;
  margin-bottom: 0.14rem;
} */
.msgccl {
  background-color: #ffffff;
  padding: 0.46rem 0.23rem;
  padding-bottom: 0.7rem;
  border-radius: 0.1rem;
  margin-bottom: 0.14rem;
  position: relative;
}
.himgclass {
  position: absolute;
  width: 0.6rem;
  bottom: 0;
  right: 0;
}
.kaic {
  font-size: 0.24rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ff5f19;
  line-height: 0.33rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 2.3rem;
  text-align: right;
}
.mac {
  margin-left: 0.3rem;
  width: 100%;
}
.xiao {
  font-size: 0.24rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #696969;
  line-height: 0.33rem;
  margin-top: 0.14rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.xinxi {
  display: flex;
  align-items: center;
  width: 100%;
}
.nayu {
  display: flex;
  align-items: center;
}
.nayu img {
  width: 0.4rem;
  height: 0.4rem;
  margin: 0 0.14rem;
}
.nayu > div:first-child {
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222222;
  line-height: 0.42rem;
}
.nayu > :nth-child(3) {
  font-size: 0.24rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #cccccc;
  line-height: 0.33rem;
}
.userclass {
  width: 1.07rem;
  height: 1.07rem;
  position: relative;
}
.userclass img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.userclass .shimingclass {
  position: absolute;
  border-radius: 0;
  bottom: -0.15rem;
  left: 50%;
  transform: translateX(-50%);
  width: 1rem;
  height: auto;
}
.allmsgclass {
  margin: 0.21rem 0.24rem;
}
.msgclass {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.daohang {
  border-bottom-style: solid;
  border-bottom-color: #ebeef5;
  border-bottom-width: 1px;
}
.sh1 {
  display: flex;
  align-items: center;

  font-size: 0.32rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #606266;
  line-height: 0.48rem;

  justify-content: center;
  padding: 0.24rem 0.35rem;
  background-color: white;
}
.sh1 > :nth-child(2) {
  margin: 0 1.1rem;
}

.sh2 {
  padding: 0.13rem 0.32rem;
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 0.42rem;
  background: #eeeeee;
  border-radius: 0.1rem;
  position: relative;
}
.sh3 {
  font-size: 0.28rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 0.48rem;
  border-radius: 0.1rem;
  position: relative;
  display: flex;
  align-items: center;
  color: #606266;
}
.sh3 img {
  width: 0.23rem;
  height: 0.23rem;
  margin-left: 0.1rem;
}
.sh2 img {
  position: absolute;
  width: 0.12rem;
  bottom: 0.11rem;
  right: 0.13rem;
}
.tiaojian {
  position: absolute;
  width: 0.3rem;
  height: 0.3rem;
  text-align: center;
  line-height: 0.3rem;
  border-radius: 50%;
  background-color: #ff5f19;
  color: #ffffff;
  font-size: 0.2rem;
  top: -0.1rem;
  right: -0.15rem;
}
.sem {
  color: #ff6d20;
  font-weight: 500;
  position: relative;
}
.sem::after {
  content: "";
  width: 0.44rem;
  height: 0.1rem;
  background-color: #ff6d20;
  border-radius: 0.05rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -0.28rem;
}
.hy2c {
  margin-top: 0.14rem;
}
.hycla2 {
  margin-top: 0.14rem;
}
</style>
